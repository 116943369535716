import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "book" */ '../views/BookView.vue')
  },
  {
    path: '/reset_user_password/:id',
    name: 'password',
    component: () => import('../views/PasswordView.vue')
  },
  {
    path: '/offers/:site_id/:offer_id',
    name: 'offers',
    component: () => import('../views/OffersView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/blog/:id',
    name: 'blog-id',
    component: () => import('../views/BlogPageView.vue')
  },
  {
    path: '/on-demand',
    name: 'on-demand',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnDemandView.vue')
  },
  {
    path: '/on-demand/:id',
    name: 'on-demand-id',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnDemandWatchView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/CheckoutView.vue')
  },
  {
    path: '/admin/',
    name: 'admin home',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminHome" */ '../views/admin/HomeView.vue')
  },
  {
    path: '/admin/login',
    name: 'admin login',
    component: () => import(/* webpackChunkName: "adminLogin" */ '../views/admin/LoginView.vue')
  },
  {
    path: '/admin/users',
    name: 'users',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminUsers" */ '../views/admin/UsersView.vue')
  },
  {
    path: '/admin/times',
    name: 'times',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminTimes" */ '../views/admin/TimesView.vue')
  },
  {
    path: '/admin/prices',
    name: 'prices',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminPrices" */ '../views/admin/PricesView.vue')
  },
  {
    path: '/admin/analytics',
    name: 'analytics',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminAnalytics" */ '../views/admin/AnalyticsView.vue')
  },
  {
    path: '/admin/on-demand',
    name: 'admin on-demand',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminOnDemand" */ '../views/admin/OnDemandView.vue')
  },
  {
    path: '/admin/blogs',
    name: 'blogs',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminBlogs" */ '../views/admin/BlogsView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not found',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NoPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.userSession) {
      next({ name: 'login', query: { p: to.name } });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!sessionStorage.adminSession) {
      next({ name: 'admin login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
