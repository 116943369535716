<template>
  <div class="home">

    <div id="landing">
      <h1>Welcome to Fitness Studios</h1>
      <p>Your Fitness Journey Begins Here</p>
      <button @click="this.$router.push('/book')">Book Now <i class="fas fa-arrow-right"></i></button>
    </div>

    <div id="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5906.4202562665505!2d-0.08883315779499874!3d51.506908117642475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876034fcfd170e7%3A0xb5fcfd348020fae!2sHMS%20Belfast!5e0!3m2!1sen!2suk!4v1692610187580!5m2!1sen!2suk"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
      <div class="text-box">
        <h2>Find Us Here</h2>
        <p>123 Fitness Street</p>
        <p>London</p>
        <p>SW1A 1AA</p>
        <p>info@londonfitnessstudio.com</p>
      </div>
    </div>

    <div id="about">
      <h2>WHAT WE'RE ABOUT</h2>
      <p>London Fitness Studio is your premier destination for fitness and wellness. Lorem ipsum dolor sit amet
        consectetur adipiscing elit. Nullam lectus erat, consectetur eu sapien eget rhoncus consectetur sem. Nam sed
        libero vitae erat fermentum tincidunt. Ut sit amet velit quis elit tincidunt facilisis.</p>
      <span>
        <button @click="this.$router.push('/login')">Sign up</button>
        <button @click="this.$router.push('/book')">Book</button>
      </span>
    </div>

    <div id="features">
      <div class="feature">
        <h2>State-of-the-Art Facilities</h2>
        <p>Our studio boasts top-notch equipment and facilities for your fitness needs. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Quisquam, voluptatum.</p>
        <i class="fas fa-3x fa-dumbbell"></i>
      </div>

      <div class="feature">
        <h2>Expert Trainers</h2>
        <p>Our certified trainers are here to guide you on your fitness journey. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Quisquam, voluptatum.</p>
        <i class="fas fa-3x fa-users"></i>
      </div>

      <div class="feature">
        <h2>Personalized Workouts</h2>
        <p>We tailor workouts to your goals and fitness level. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Quisquam, voluptatum.</p>
        <i class="fas fa-3x fa-heart"></i>
      </div>
    </div>

    <div id="tagline">
      <div id="tagline-overlay"></div>
      <h1>Your Path to a Healthier You</h1>
    </div>

    <div id="final-cta">
      <h2>Ready to Get Started?</h2>
      <button @click="this.$router.push('/book')">Book Now</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  }
}
</script>

<style scoped lang="scss">
@import "/src/assets/styles/variables.scss";

#landing {
  width: 100%;
  height: 100vh;
  background: url('/src/assets/img1.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 1px 1px 10px #000000, 1px 1px 15px #000000;
  }

  p {
    font-size: 1.5rem;
    text-shadow: 1px 1px 10px #000000, 1px 1px 15px #000000;
    text-transform: uppercase;
  }

  button {
    border: solid 3px white;
    border-radius: 100px;
    padding: 10px 40px;
    color: white;
    font-size: 1.5rem;
    margin-top: 20px;
    background: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-shadow: 1px 1px 12px #000000b9;

    i {
      margin-left: 10px;
    }

    &:hover {
      border-color: $colourP;
      background: $colourP;
      text-shadow: none;
    }
  }
}

#map {
  width: 100%;
  height: 500px;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .text-box {
    position: absolute;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: left;
    box-shadow: 1px 1px 12px #000000b9;
    width: 280px;
    height: 230px;
    top: 120px;
    left: 10%;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}

#about {
  width: 100%;
  text-align: center;
  padding: 80px 0;
  font-size: 1.5em;

  p {
    max-width: 800px;
    margin: auto;

  }

  span {
    width: 100%;
    max-width: 350px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;

    button {
      border: solid 3px $colourS;
      padding: 10px 40px;
      font-size: 1.3rem;
      color: inherit;
      font-weight: bold;
      margin-top: 20px;
      background: none;
      cursor: pointer;
      transition: all 0.1s ease-in-out;

      &:hover {
        border-color: $colourS;
        background: $colourS;
        text-shadow: none;
      }

      &:last-child {
        background-color: $colourS;

        &:hover {
          border-color: darken($colourS, 10%);
          background-color: darken($colourS, 10%);
        }
      }
    }
  }
}

#features {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  flex-wrap: wrap;
  background-image: url('/src/assets/img2.jpg');
  background-size: cover;
  background-position: center center;

  .feature {
    width: 300px;
    height: 300px;
    background-color: white;
    text-align: center;
    margin: 50px;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    &:hover {
      transform: scale(1.05);
    }

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }

    i {
      color: $colourT;
    }
  }
}

#tagline {
  width: 100%;
  height: 160px;
  padding: 80px 0;
  // background-color: $colourP;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  position: relative;
  overflow: hidden;

  #tagline-overlay {
    background-color: white;
    width: 120%;
    height: 120%;
    left: -10%;
    transform: rotate(-7deg);
    position: absolute;
  }

  h1 {
    max-width: 1000px;
    z-index: 2;
  }
}

#final-cta {
  width: 100%;
  height: 80vh;
  background-image: url('/src/assets/img3.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.8rem;
  text-shadow: 1px 1px 12px #000000da;

  button {
    border: solid 3px white;
    padding: 10px 40px;
    color: white;
    font-size: 1.5rem;
    margin-top: 20px;
    background: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-shadow: 1px 1px 12px #000000b9;

    &:hover {
      border-color: $colourP;
      background: $colourP;
      text-shadow: none;
    }
  }
}

@media (max-width: 600px) {
  #map {
    height: 600px;
    position: relative;

    .text-box {
      scale: 0.8;
      top: 10px;
      height: 180px;
      left: calc(50% - 160px);

      h2 {
        margin-top: 0;
      }
    }
  }

  #about {
    padding: 80px 20px;
    box-sizing: border-box;
  }

  #tagline {
    font-size: 1rem;
    padding: 80px 40px;
    width: calc(100% - 80px);
  }
}</style>
