<template>
  <nav v-if="!admin && mobileNav">
    <div id="mobileNavBar">
      <img @click="() => { this.$router.push('/'); this.showNav = false }" src="@/assets/logo.png" alt="logo" />
      <i class="fa fa-bars" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-times" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div class="mobileNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/">Home</router-link>
      <router-link @click="showNav = false" to="/about">About</router-link>
      <router-link @click="showNav = false" to="/blog">Blog</router-link>
      <router-link @click="showNav = false" to="/on-demand">On Demand</router-link>
      <router-link @click="showNav = false" class="book" to="/book">Book</router-link>
      <router-link @click="showNav = false" class="join" v-if="!this.authed" to="/login">Join</router-link>
      <router-link @click="showNav = false" v-if="this.authed" class="book" to="/profile">My Account</router-link>
      <!-- <a v-if="this.email == 'oliver@decentapps.co.uk'" href="/admin">Admin</a> -->
    </div>
  </nav>
  <nav v-if="!admin && nav">
    <img id="floatImg" @click="this.$router.push('/')" src="@/assets/logo.png" alt="logo" />
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link to="/blog">Blog</router-link>
    <router-link to="/on-demand">On Demand</router-link>
    <router-link class="book" to="/book">Book</router-link>
    <router-link class="join" v-if="!this.authed" to="/login">Join</router-link>
    <router-link v-if="this.authed" to="/profile" class="book">My Account</router-link>
    <!-- <a v-if="this.email == 'oliver@decentapps.co.uk'" href="/admin">Admin</a> -->
  </nav>
  <nav v-if="admin && nav" id="adminDesktopNav" :class="showNav && 'navEnlarge'">
    <i class="fa" :class="showNav ? 'fa-chevron-left' : 'fa-chevron-right'" id="openNav" @click="toggleAdminNav"></i>
    <router-link to="/admin"><img src="@/assets/logo.png" alt="logo" />
      <span>Bookings</span></router-link>
    <router-link to="/admin/times"><i class="fa-regular fa-calendar"></i>
      <span>Schedule</span></router-link>
    <router-link to="/admin/prices"><i class="fa fa-pound-sign"></i>
      <span>Prices</span></router-link>
    <router-link to="/admin/analytics"><i class="fa fa-chart-line"></i>
      <span>Analytics</span></router-link>
    <router-link to="/admin/users"><i class="fa fa-users"></i>
      <span>Users</span></router-link>
    <router-link to="/admin/on-demand"><i class="fa fa-play"></i>
      <span>On Demand</span></router-link>
    <router-link to="/admin/blogs"><i class="fa fa-newspaper"></i>
      <span>Blog</span></router-link>
    <a v-if="authed" href="/" id="logout"><i class="fa-solid fa-right-from-bracket"></i>
      <span>Logout</span></a>
  </nav>
  <nav v-if="admin && mobileNav" id="adminMobileNav">
    <div id="mobileAdminNavBar">
      <i class="fa fa-caret-down" id="openNav" :class="!showNav && 'active'" @click="showNav = !showNav"></i>
      <i class="fa fa-caret-up" id="closeNav" :class="showNav && 'active'" @click="showNav = !showNav"></i>
    </div>
    <div id="mobileAdminNavDropdown" :class="showNav && 'dropdownOpen'">
      <router-link @click="showNav = false" to="/admin"><img src="@/assets/logo.png" alt="logo" />
        Bookings</router-link>
      <router-link @click="showNav = false" to="/admin/times"><i class="fa fa-calendar"></i> Schedule</router-link>
      <router-link @click="showNav = false" to="/admin/prices"><i class="fa fa-pound-sign"></i> Prices</router-link>
      <router-link @click="showNav = false" to="/admin/analytics"><i class="fa fa-chart-line"></i>
        Analytics</router-link>
      <router-link @click="showNav = false" to="/admin/users"><i class="fa fa-users"></i> Users</router-link>
      <router-link @click="showNav = false" to="/admin/on-demand"><i class="fa fa-play"></i> On-Demand</router-link>
    </div>
  </nav>
  <router-view class="router-view" :class="showNav && 'navEnlarge'" />

  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {
      authed: false,
      admin: false,
      nav: true,
      email: localStorage.email,
      mobileNav: false,
      showNav: localStorage.showNav == 'true' ? true : false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('userSession');
      localStorage.removeItem('operator');
      localStorage.removeItem('admin');
      this.$router.push('/login');
      this.authed = false;
      this.operator = false;
      this.admin = false;
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      }
    },
    toggleAdminNav() {
      if (this.showNav) {
        this.showNav = false;
        localStorage.showNav = 'false';
      } else {
        this.showNav = true;
        localStorage.showNav = 'true';
      }
    }
  },
  mounted() {
    if (window.location.pathname.split('/')[1] == 'admin') {
      this.admin = true;
      document.getElementsByTagName('body')[0].classList.add('admin');
      if (localStorage.getItem('adminSession')) this.authed = true;
      else this.$router.push('/admin/login');
    } else {
      document.getElementsByTagName('body')[0].classList.add('main');
      if (localStorage.getItem('userSession')) this.authed = true;
    }

    this.setSize();
    window.addEventListener('resize', this.setSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.main {
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(to bottom, #191b1d, #1b1d1f00);
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 1.1rem;
      z-index: 10;

      img {
        position: fixed;
        top: 25px;
        left: 10%;
        height: 50px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: normal;
        font-size: 0.9em;
        // margin: 0 20px;
        // text-shadow: 1px 1px 12px #000000;
        padding: 5px 20px;
        border-right: 1px solid white;

        &.router-link-exact-active {
          text-decoration: underline;
        }

        &:last-child {
          border-right: none;
        }

        &.join,
        &.book {
          font-weight: bold;
        }
      }
    }
  }
}

.admin {
  background-color: #272a2c;

  .router-view, #app {
    position: fixed;
    top: 0;
    right: 00px;
    width: calc(100% - 90px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    background-color: #272a2c;
    transition: width 0.2s;

    &.navEnlarge {
      width: calc(100% - 200px);
    }

    h1 {
      color: $colourP;
    }

    button,
    select,
    option,
    input {
      font-family: inherit;
      font-size: inherit;
    }
  }

  #adminDesktopNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: calc(100% - 50px);
    padding: 10px 15px;
    margin: 16px 10px;
    border-radius: 5px;
    background-color: #1b1e1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: width 0.2s ease-in-out;

    a {
      font-weight: bold;
      text-decoration: none;
      color: white;
      display: block;
      width: 50px;
      height: 50px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      transition: background-color 0.1s ease-in-out, width 0.2s ease-in-out;
      font-size: 20px;

      &.router-link-exact-active {
        background-color: #58595B;
        // color: $colourP;
      }

      img {
        width: 30px;
      }

      span {
        visibility: hidden;
        width: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }

    #openNav {
      position: absolute;
      top: 10px;
      left: 20px;
      padding: 15px;
      font-size: medium;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: $colourP;
      }
    }

    &.navEnlarge {
      width: 160px;

      a {
        width: 160px;
        text-align: left;
        justify-content: flex-start;
        padding-left: 5px;

        i {
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          margin: 0 6px;
        }

        span {
          visibility: visible;
          width: auto;
          opacity: 1;
          font-weight: normal;
          font-size: small;
          margin-left: 10px;
          white-space: nowrap;
        }
      }
    }

    #logout {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    #app {
      left: 0;
      width: 100%;
    }
  }
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

// Loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .router-view, #app {
    left: 0;
    width: 100%;
  }

  #openNav,
  #closeNav {
    position: absolute;
    right: 40px;
    opacity: 0;
    rotate: 90deg;
    transition: 0.2s;
    cursor: pointer;
  }

  #closeNav {
    rotate: -90deg;
  }

  #openNav.active,
  #closeNav.active {
    z-index: 3;
    opacity: 1;
    rotate: 0deg;
  }

  #mobileNavBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: linear-gradient(to bottom, #000000, #00000000);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10%;
    box-sizing: border-box;
    font-size: 1.1rem;
    z-index: 10;

    img {
      height: 50px;
    }

    i {
      font-size: 1.8rem;
      padding: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .mobileNavDropdown {
    height: 0px;
    width: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 100px;
    transition: 0.2s;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    a {
      margin-top: 40px !important;
      margin-left: 40px !important;
      font-size: 1.5rem;
      width: fit-content;
      border-right: none !important;
    }
  }

  .dropdownOpen {
    height: 100vh;
    background-image: linear-gradient(to bottom, #000000, #000000, #000000, #00000000);
  }

  .admin {
    #mobileAdminNavBar {
      position: fixed;
      top: 10px;
      left: 100px;
      z-index: 100;
    }

    #mobileAdminNavDropdown {
      display: none;
      position: fixed;
      top: 50px;
      left: 0px;
      width: 100vw;
      height: 500px;
      background-color: #000000;
      justify-content: space-evenly;
      align-items: flex-start;
      z-index: 99;

      &.dropdownOpen {
        display: flex;
        flex-direction: column;
        padding: 20px 40px;
        box-sizing: border-box;
      }

      &::before {
        content: "";
        position: absolute;
        top: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: black;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -49px;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom, #000000, #000000, #00000000);
      }

      a {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin-bottom: 30px;
        color: rgba(255, 255, 255, 0.723);
      }

      img {
        width: 50px;
        margin-right: 20px;
      }

      i {
        font-size: 2rem;
        width: 50px;
        margin-right: 20px;
        color: white;
      }
    }

    #openNav,
    #closeNav {
      padding: 20px;
      opacity: 0;
      rotate: 90deg;
      transition: 0.2s;
      cursor: pointer;
    }

    #closeNav {
      rotate: -90deg;
    }

    #openNav.active,
    #closeNav.active {
      z-index: 3;
      opacity: 1;
      rotate: 0deg;
    }
  }
}
</style>
